import React from 'react';
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom';
import { SignUp } from './Signup';
import { Home } from './Home';
import { ContactUs } from './ContactUs';
import { TicketRedirect } from './TicketRedirect';
import './App.css'; 
import headerImage from './images/header-image.jpg'

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <header>          
          <nav className="navbar">
            <ul className="navbar-list">
              <li className="navbar-item">
                <Link to="/" className="navbar-link">Home</Link>
              </li>        
              <li className="navbar-item">
                <Link to="/contact" className="navbar-link">Contact Us</Link>
              </li>
              {/* <li className="navbar-item">
                <a href="https://www.tix.com/ticket-sales/collingswoodarts/7008/event/1353487?fbclid=iwar0wy6tkpf4kc0pkhdgqa_yrljwxwfxzjzwk2p6wgoyc_5ubrg01pbke-ki" className="navbar-link">Get Tickets</a>
              </li> */}
            </ul>
          </nav>
          <img src={headerImage} alt="Header" className="header-image" />
        </header>
        <div className='container'>
          <Routes>
            <Route path="/" element={<Home />} /> 
            <Route path="/sign-up" exact element={<SignUp />} />
            <Route path="/contact" exact element={<ContactUs />} /> 
            <Route path="/tickets" exact element={<TicketRedirect />} />          
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;