import React from 'react';

export const TicketRedirect = () => {
  React.useEffect(() => {
    window.location.href = "https://www.tix.com/ticket-sales/collingswoodarts/7008/event/1353487?fbclid=iwar0wy6tkpf4kc0pkhdgqa_yrljwxwfxzjzwk2p6wgoyc_5ubrg01pbke-ki";
  }, []);

  // You can also render a message or component while the redirect is happening.
  return null;
};

